import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModDatafactCCAA from "../../../components/Chronology/Modules/ModDatafactCCAA";
import ModDashedLine from "../../../components/Chronology/Modules/ModDashedLine";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import ModGraph from "../../../components/Chronology/Modules/ModGraph";

export const frontmatter = {
  title: "Día 63",
  week: "Semana 9",
  day: "15",
  month: "may",
  monthNumber: "05",
  date: "2020-05-15",
  path: "/cronologia/semana-09#dia-15-may/",
};
const Day63 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModText>
          <strong>Mayor crecimiento diario</strong>
        </ModText>
        <ModDatafactCCAA
          fecha={frontmatter.date}
          ccaa="es-ct"
          data="casosConfirmadosDiario"
        />
        <ModDashedLine />

        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
        <ModImage
          src="/images/svg/12_may_cuarentena-fronteras.svg"
          alt="control sanitario fronteras"
        />

        <ModText>
          A partir de hoy, los equipos de <strong>Sanidad Exterior</strong>{" "}
          empiezan a realizar
          <strong> controles sanitarios en puertos y aeropuertos</strong> a
          todos los viajeros procedentes del extranjero. Durante su estancia en
          España, estos deben permanecer en aislamiento 14 días.
        </ModText>
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 1.627 nuevos positivos y 138 personas fallecidas.
          Permanecen hospitalizadas 124.571 personas, 346 más que el día
          anterior, y 1.409 reciben el alta. El número de casos ingresados en la
          UCI asciende a 11.493, lo que supone un aumento de 29 personas.
        </ModText>
        <ModText>
          Respecto al número de casos totales confirmados, la tasa de letalidad
          es del 10,0 % y la tasa de recuperados del 52,8 %.
        </ModText>
        <ModGraph
          name="15-05-es-fallecidos-semanal"
          alt="casos fallecidos por semana en España con Covid-19"
        />
        <ModGraph
          name="15-05-es-recuperados-semanal"
          alt="casos recuperados por semana en España con Covid-19"
        />
        <ModText>
          El número de casos activos, considerado como el número de casos
          confirmados - (recuperados-fallecidos), disminuye en términos
          absolutos por tercera semana consecutiva. Esta contracción en el
          número de contagiosos es una buena noticia, por el impacto positivo en
          la velocidad a la que se frena la expansión de la pandemia.
        </ModText>

        <ModGraph
          src="/graphs/15-05-es-activos-semanal"
          alt="casos activos por semana en España con Covid-19"
        />
        <ModText>
          El ministro de Sanidad, Salvador Illa, ha anunciado hoy nuevas medidas
          de{" "}
          <strong>
            flexibilización de las restricciones de ámbito nacional
          </strong>{" "}
          en aplicación del Plan para la Transición hacia una Nueva Normalidad
          iniciado hace dos semanas. Estas medidas, corresponden al progreso de
          unidades territoriales a la fase 1, la fase 2 y la flexibilización de
          la fase 0.
        </ModText>
        <ModText>
          De este modo, cerca del 70% de la población española (32 millones de
          personas) se encuentra desde este lunes en fase 1 y el 30% (14
          millones) permanece en una fase 0 flexibilizada. Y alrededor de 45.000
          ciudadanos progresan a fase 2.
        </ModText>

        <ModTwoCols
          src="/images/svg/22_mar_mascarillas_sanitarios.svg"
          alt="Sanitarios"
          small={false}
          inverted={false}
        >
          Por otra parte, el Ministerio de Sanidad ha publicado una Orden que
          recoge la adaptación del procedimiento habitual de adjudicación de
          <strong> plazas de formación sanitaria especializada</strong>. El
          acceso al trámite de solicitud de plaza se realizará a través de la
          sede electrónica del mismo.
        </ModTwoCols>
        <ModText>
          El texto también recoge una ampliación de 143 plazas en la oferta
          inicialmente aprobada.
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day63;
